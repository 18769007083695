import * as gen from 'unique-names-generator';
import md5 from 'crypto-js/md5';

const roomConfig: gen.Config = {
    dictionaries: [gen.adjectives, gen.names, gen.animals, gen.starWars, gen.countries, gen.colors],
    length: 6,
    separator: '-',
};
let prename = gen.uniqueNamesGenerator(roomConfig).split('-');
console.log(prename);
export const randomRoomName = () => prename[5] + '-' + md5(prename[0] + prename[4] + prename[3] + prename[1] + prename[2]);

export const getPermanentName = () => localStorage.getItem('screego_name') ?? undefined;
export const setPermanentName = (name: string) => localStorage.setItem('screego_name', name);
